import styled from 'styled-components'

import Section from '@components/core/section'
import Paragraph from '@components/core/paragraph'
import H1 from '@components/core/headings/h1'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const ServicesHeadingSection = styled(Section)`
  /* margin-top: ${toRem(60)}; */
`

export const ServicesH1 = styled(H1)`
  margin-bottom: ${theme.spacing[2]};
`

export const ServicesGrid = styled.ul`
  display: grid;
  grid-template-rows: 1fr;
  gap: ${theme.spacing[3]};

  @media screen and (min-width: ${theme.breakpoints.md}) {
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: ${theme.breakpoints['4k']}) {
    grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
  }
`
