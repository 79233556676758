import React from 'react'
import WarningIcon from '../icons/warning'

import * as S from './styles'

export default function Announcement() {
  return (
    <S.AnnouncementContainer>
        <WarningIcon />
      <S.AnnouncementContent>
        Vážení pacienti, ordinace dentální hygieny je otevřená v omezeném provozu, a to dva dny v týdnu v dopoledních hodinách. Objednání je možné <b>pouze přes SMS nebo email</b>. Děkujeme za pochopení a přejeme vše nejlepší v roce 2025.
      </S.AnnouncementContent>
    </S.AnnouncementContainer>
  )
}
