export interface IService {
  id: string
  name: string
  shortDescription: string
  longDescription: string
  timeRange: string
  imageKey: string

  price?: number | number[]
}

export const serviceData: IService[] = [
  {
    id: 'initial-examination',
    name: 'Vstupní vyšetření',
    shortDescription: 'První návštěva případně nepravidelná návštěva po 2 letech.',
    longDescription:
      'Zahrnuje anamnézu, komplexní vyšetření, odstranění akutního dráždění dásně (zubní kámen, povlak), leštění zubů, výběr pomůcek a následný nácvik používání pomůcek v domácí péči.',
    timeRange: 'max 60 min',
    price: 1300,
    imageKey: 'initialExaminationService',
  },
  {
    id: 'recall-ordinary',
    name: 'Recall - pravidelná návštěva',
    shortDescription: 'Pravidelná návštěva co 6 měsíců.',
    longDescription:
      'Systém pravidelných udržovacích terapii. Kontrola stavu dutiny ústní, odstranění zubního kamene, povlaku, pigmentací a leštění zubů. Preventivní program pro zachování funkce chrupu i estetiky.',
    timeRange: 'max 45 min',
    price: 1000,
    imageKey: 'recallService',
  },
  {
    id: 'recall-unordinary',
    name: 'Recall - nepravidelná návštěva',
    shortDescription: 'Nepravidelná návštěva po 12 a více měsících.',
    longDescription:
      'Systém udržovacích terapii po 12 a více měsících. Kontrola stavu dutiny ústní, odstranění zubního kamene, povlaku, pigmentací a leštění zubů. Preventivní program pro zachování funkce chrupu i estetiky.',
    timeRange: 'max 55 min',
    price: 1100,
    imageKey: 'unordinaryRecallService',
  },
  {
    id: 'children',
    name: 'Děti do 15 let',
    shortDescription: 'Péče o dětské pacienty.',
    longDescription:
      'Komplexní vyšetření, odstranění zubního kamene a povlaku, leštění zubů, výběr správných pomůcek a následný nácvik používání v domácí péči.',
    timeRange: '30 - 45 min',
    price: [600, 800],
    imageKey: 'childrenService',
  },
  {
    id: 'tooth-whitening',
    name: 'Bělení zubů',
    shortDescription: 'Program k zesvětlení vašich zubů.',
    longDescription:
      'Program k zesvětlení vašich zubů. Bělení zubů se provádí nejdříve vždy po vstupním vyšetření a konzultaci s ošetřujícím lékařem.',
    timeRange: 'individuální',
    price: 3990,
    imageKey: 'toothWhiteningService',
  },
]
