import React from 'react'

import H2 from '@components/core/headings/h2'
import Paragraph from '@components/core/paragraph'
import ServiceCard from './service-card'

import * as S from './styles'

import useServicesData from '@hooks/use-services-data'

export default function ServicesList() {
  const services = useServicesData()

  return (
    <S.ServicesHeadingSection>
      <S.ServicesH1>Ceník služeb</S.ServicesH1>

      <Paragraph>
        Ceny se mohou individuálně upravit dle závažnosti onemocnění, nebo stavu dutiny ústní. Každý
        pacient při vstupním vyšetření dostane informaci o potřebě časové i finanční náročnosti. V
        každé službě je zahrnuta cena AIR FLOW (pískování) a lokální fluoridace.
      </Paragraph>

      <H2>Nabízené služby</H2>

      <S.ServicesGrid>
        {services.map((service) => (
          <li key={service.id}>
            <ServiceCard
              title={service.name}
              fluidImage={service.fluidImage}
              description={service.longDescription}
              timeRange={service.timeRange}
              price={service.price}
            />
          </li>
        ))}
      </S.ServicesGrid>
    </S.ServicesHeadingSection>
  )
}
